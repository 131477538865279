import type { ReadonlyDeep } from 'type-fest';
import type { ToRefs } from 'vue';

// most of this is taken from: https://github.com/nuxt-community/device-module/blob/master/src/runtime/generateFlags.ts
const MOBILE_OR_TABLET1_RE = /(?:android|bb\d|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|FBAN|FBAV|fennec|hiptop|iemobile|ip(?:hone|od)|Instagram|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(?:ob|in)i|palm(?: os)?|phone|p(?:ixi|re)\/|plucker|pocket|psp|series(?:4|6)0|symbian|treo|up\.(?:browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i;
const MOBILE_OR_TABLET2_RE = /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(?:er|oo|s-)|ai(?:ko|rn)|al(?:av|ca|co)|amoi|an(?:ex|ny|yw)|aptu|ar(?:ch|go)|as(?:te|us)|attw|au(?:di|-m|r |s )|avan|be(?:ck|ll|nq)|bi(?:lb|rd)|bl(?:ac|az)|br(?:e|v)w|bumb|bw-(?:n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(?:mp|nd)|craw|da(?:it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(?:c|p)o|ds(?:12|-d)|el(?:49|ai)|em(?:l2|ul)|er(?:ic|k0)|esl8|ez(?:[4-7]0|os|wa|ze)|fetc|fly(?:-|_)|g1 u|g560|gene|gf-5|g-mo|go(?:\.w|od)|gr(?:ad|un)|haie|hcit|hd-[mpt]|hei-|hi(?:pt|ta)|hp(?: i|ip)|hs-c|ht(?:c[\- _agpst]|tp)|hu(?:aw|tc)|i-(?:20|go|ma)|i230|iac[ \-/]|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(?:t|v)a|jbro|jemu|jigs|kddi|keji|kgt(?: |\/)|klon|kpt |kwc-|kyo(?:c|k)|le(?:no|xi)|lg(?: g|\/[klu]|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(?:te|ui|xo)|mc(?:01|21|ca)|m-cr|me(?:rc|ri)|mi(?:o8|oa|ts)|mmef|mo(?:01|02|bi|de|do|t[\- ov]|zz)|mt(?:50|p1|v )|mwbp|mywa|n10[0-2]|n20[23]|n30(?:0|2)|n50[025]|n7(?:0(?:0|1)|10)|ne(?:(?:c|m)-|on|tf|wf|wg|wt)|nok(?:6|i)|nzph|o2im|op(?:ti|wv)|oran|owg1|p800|pan[adt]|pdxg|pg(?:13|-[1-8c])|phil|pire|pl(?:ay|uc)|pn-2|po(?:ck|rt|se)|prox|psio|pt-g|qa-a|qc(?:07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(?:ve|zo)|s55\/|sa(?:ge|ma|mm|ms|ny|va)|sc(?:01|h-|oo|p-)|sdk\/|se(?:c[\-01]|47|mc|nd|ri)|sgh-|shar|sie(?:-|m)|sk-0|sl(?:45|id)|sm(?:al|ar|b3|it|t5)|so(?:ft|ny)|sp(?:01|h-|v-|v )|sy(?:01|mb)|t2(?:18|50)|t6(?:00|10|18)|ta(?:gt|lk)|tcl-|tdg-|tel(?:i|m)|tim-|t-mo|to(?:pl|sh)|ts(?:70|m-|m3|m5)|tx-9|up(?:\.b|g1|si)|utst|v400|v750|veri|vi(?:rg|te)|vk(?:40|5[0-3]|-v)|vm40|voda|vulc|vx(?:52|53|60|61|70|80|81|83|85|98)|w3c(?:-| )|webc|whit|wi(?:g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i;
const IS_FIREFOX_RE = /firefox|iceweasel|fxios/i;
const IS_SAFARI_RE = /version\/[\w.,]+ .*safari/i;
const IS_MAC_RE = /macintosh|macintel|macppc|mac68k|macos/i;

const isMobileOrTablet = (a: string) => MOBILE_OR_TABLET1_RE.test(a) || MOBILE_OR_TABLET2_RE.test((a || '').slice(0, 4));
const isFirefox = (a: string) => IS_FIREFOX_RE.test(a);
const isSafari = (a: string) => IS_SAFARI_RE.test(a);
const isMac = (a: string) => IS_MAC_RE.test(a);

export function useDevice() {
  const state = useState(() => {
    const userAgent = import.meta.server
      ? useRequestHeader('user-agent')!
      : navigator.userAgent;

    return {
      isMac: isMac(userAgent),
      isFirefox: isFirefox(userAgent),
      isSafari: isSafari(userAgent),
      isSmallScreen: import.meta.server
        ? isMobileOrTablet(userAgent)
        : window.innerWidth < breakpoints.tablet,
    };
  });

  return toRefs(state.value) as ReadonlyDeep<ToRefs<typeof state.value>>;
}
