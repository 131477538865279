import { default as loginVHinW0V3stMeta } from "/source/pages/(auth)/login.vue?macro=true";
import { default as registerQOwGc9eCrHMeta } from "/source/pages/(auth)/register.vue?macro=true";
import { default as _91note_93I22I9A0TXgMeta } from "/source/pages/@[user]/[...folders]/[note].vue?macro=true";
import { default as _64_91user_93LVBlxFvFaxMeta } from "/source/pages/@[user].vue?macro=true";
import { default as aboutGUyM2YkJbtMeta } from "/source/pages/about.vue?macro=true";
import { default as index1wXsoGjp9ZMeta } from "/source/pages/index.vue?macro=true";
import { default as ask_45usernameR7BquffcUgMeta } from "/source/pages/oauth/ask-username.vue?macro=true";
import { default as _91link_93KnB1D4uVIzMeta } from "/source/pages/view/[link].vue?macro=true";
export default [
  {
    name: "login",
    path: "/login",
    meta: loginVHinW0V3stMeta || {},
    component: () => import("/source/pages/(auth)/login.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerQOwGc9eCrHMeta || {},
    component: () => import("/source/pages/(auth)/register.vue")
  },
  {
    name: "@user",
    path: "/@:user()",
    meta: _64_91user_93LVBlxFvFaxMeta || {},
    component: () => import("/source/pages/@[user].vue"),
    children: [
  {
    name: "@user-folders-note",
    path: ":folders(.*)*/:note()",
    component: () => import("/source/pages/@[user]/[...folders]/[note].vue")
  }
]
  },
  {
    name: "about",
    path: "/about",
    meta: aboutGUyM2YkJbtMeta || {},
    component: () => import("/source/pages/about.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index1wXsoGjp9ZMeta || {},
    component: () => import("/source/pages/index.vue")
  },
  {
    name: "oauth-ask-username",
    path: "/oauth/ask-username",
    meta: ask_45usernameR7BquffcUgMeta || {},
    component: () => import("/source/pages/oauth/ask-username.vue")
  },
  {
    name: "view-link",
    path: "/view/:link()",
    component: () => import("/source/pages/view/[link].vue")
  }
]